<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
                    <div>
                        <p class="pb2 f3 b">Lifespan</p>
                    </div>
                    <div>
                        <button class="mr4">+ Add Life Span</button>
                        <button class="btn2">Export</button>
                    </div>
                </div>
            </div>
            <!-- Sales, purchase expenses others -->

            <div class="scrollmenu w-90 center">
                <table class="w-100 tl mt3" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="pb3 pr3 box-border-bottom tl">Group</th>
                            <th class="pb3 pr3 box-border-bottom tl">Category</th>
                            <th class="pb3 pr3 box-border-bottom tl">Total</th>
                            <th class="pb3 pr3 box-border-bottom tl"></th>
                        </tr>
                    </thead>
                    <tr v-for="(category, index) in state.categories" class="font-w1" :key="'row-'+index">
                        <td class="pv3 pr3 box-border-bottom tl">{{ category.group }}</td>
                        <td class="pv3 pr3 box-border-bottom tl">{{ category.name }}</td>
                        <td class="pv3 pr4 box-border-bottom tl flex items-center">
                            <img src="imgs/file.svg" class="pr1" alt="" /> 
                            <input 
                                placeholder="5"
                                class="update-lifeSpan"
                                v-model="category.lifespan"
                                @change="updateLifespan(index, category)"
                            />
                        </td>
                        <!-- <td class="  box-border-bottom  ">
                            <button class="btn-lifeSpan">Update</button>
                        </td> -->
                    </tr>
                </table>
            </div>
            <!-- pagination -->
            <div class="w-90 center pb5 font-w1">
                <div class="flex justify-between items-center pa3" style="background: #F6F8FE;">
                    <div class="flex items-center "></div>
                    <div class="flex">
                        <div class="active-page-number">1</div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
import OrgData from '@/components/OrgData'
import Spinner from '@/components/Spinner'

export default {
    name: 'Lifespan',
    components: { AppWrapper, OrgData, Spinner },

    setup() {
        const store = useStore()

        const state = reactive({
            categories: []
        })

        const updateLifespan = (index, data) => {
            store.dispatch('Settings/updateAssetCategory', data).then(({ success, assetId }) => {
                if (success) {
                    state.categories[index]._id = assetId
                }
            })
        }
        
        onMounted(() => {
            store.dispatch('Settings/getAssetCategories').then(({ categories }) => {
                state.categories = categories
            })
        })

        return {
            state,
            updateLifespan,
        }
    }
}
</script>

<style>
.update-lifeSpan {
    width: 50px;
}

.btn-lifeSpan {
    padding: 10px;
    font-size: 12px;
}
</style>
